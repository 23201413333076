import styled from 'styled-components'
import { CardDS, ProfileCardDS } from '../../'
import { FC } from 'react'
import { Pratas } from '../../../interfaces'
import { LAYOUT_CONSTANT } from '../../../utils/constants'

import { tokens } from '@JOTAJornalismo/jota-design-system'
import { VerticaisDesc } from './Vertical.component'
import { generateUUID } from '@App/libs/utils/geral'
import router from 'next/router'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'

const { fonts, colors } = tokens

export const { SM_PX, MD_PX, LG_PX } = LAYOUT_CONSTANT.BREAKPOINTS

const PratasDaCasa: FC<{ Pratas: Pratas[] }> = ({
    Pratas
}): React.JSX.Element => {
    const handleClickAvatar = (newsItem: any) => {
        return router.push(
            `/opiniao-e-analise/colunas/${newsItem.colunista_prata.slug}`
        )
    }

    return (
        <Container>
            <Title>Pratas da Casa</Title>
            <VerticaisDesc>Colunas dos jornalistas do JOTA</VerticaisDesc>
            <List>
                {Pratas?.map((newsItem) => (
                    <Item key={generateUUID()}>
                        <ProfileCardContainer
                            onClick={() => handleClickAvatar(newsItem)}
                        >
                            <ProfileCardDS
                                nameSurname={newsItem.colunista_prata.name}
                                description={
                                    newsItem.colunista_prata.description
                                }
                                pathAvatarImage={
                                    newsItem.colunista_prata.logo_url
                                }
                                type='tertiary'
                                hasAvatar={true}
                            />
                        </ProfileCardContainer>
                        <CardDS
                            title={newsItem.title}
                            titleSearchTerm={''}
                            text={newsItem.excerpt}
                            newsLink={`${checkSpecialOrInfoNews(
                                newsItem.categories,
                                newsItem.slug,
                                newsItem.permalink
                            )}`}
                            size='small'
                            tagLabel={newsItem.hat}
                            tagOnClick={() => {
                                router.push(
                                    `${checkSpecialOrInfoNews(
                                        newsItem.categories,
                                        newsItem.slug,
                                        newsItem.permalink
                                    )}`
                                )
                            }}
                        />
                    </Item>
                ))}
            </List>
        </Container>
    )
}

export default PratasDaCasa

const Container = styled.section`
    padding: 0px 44px 88px 44px;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        padding: 44px 36px;
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: 44px 16px;
    }
`
const Title = styled.h4`
    ${fonts.jotaHeading04()};
    color: ${colors.black.jotaBlack};
    padding: 0 0 8px 0;
    margin: 0;
`
const ProfileCardContainer = styled.div`
    article h2:hover,
    p:hover {
        text-decoration: underline;
    }

    &:hover {
        cursor: pointer;
    }
`

const List = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: start;
    gap: 44px;
    padding: 0;
    margin: 24px 0 0 0;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        grid-template-columns: 1fr;
    }

    @media only screen and (max-width: ${SM_PX}) {
        grid-template-columns: 1fr;
    }
`
const Item = styled.li`
    list-style-type: none;
    display: grid;
    gap: 16px;
`
