import styled from 'styled-components'
import { CardDS } from '../..'
import { FC } from 'react'
import { SpecialsIH } from '../../../interfaces'
import { BREAKPOINTS } from '../../../utils/constants/layout'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import { VerticaisDesc } from '../second-scroll/Vertical.component'
import router from 'next/router'
import { window } from '@App/libs/interfaces/global.interface'
const { colors, fonts, spaces } = tokens
import { addDatalayer } from '@App/libs/utils/geral'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'

/**
 * Mais Lidas Component
 * @return {React.JSX.Element}
 */
const SpecialCoverageComponent: FC<{
    specialNews: SpecialsIH
    window?: window
}> = ({ specialNews }): React.JSX.Element => {
    return (
        <SpecialCoverageContainer>
            <Title> Coberturas especiais </Title>
            <VerticaisDesc>
                Conteúdo patrocinado com o olhar do Estúdio JOTA
            </VerticaisDesc>
            <NewsContainer>
                <NewsListContainer>
                    {specialNews.posts.map((newItem: any, index: number) => (
                        <div key={newItem.id + index}>
                            <CardDS
                                title={
                                    newItem.defined_title
                                        ? newItem.defined_title
                                        : newItem.post_title
                                }
                                titleSearchTerm={''}
                                newsLink={() => {
                                    addDatalayer({
                                        event: 'click_coberturas',
                                        news_link: `${checkSpecialOrInfoNews(
                                            newItem.post_category,
                                            newItem.post_slug,
                                            newItem.post_permalink
                                        )}`
                                    })
                                    router.push(
                                        `${checkSpecialOrInfoNews(
                                            newItem.post_category,
                                            newItem.post_slug,
                                            newItem.post_permalink
                                        )}`
                                    )
                                }}
                                tagLabel={
                                    newItem.defined_hat
                                        ? newItem.defined_hat
                                        : newItem.post_hat
                                }
                                isVertical={false}
                                tagDisableHover
                                size='small'
                                image={newItem.defined_image}
                                dateTagDisableHover
                                subjectTagLabel={`${newItem.defined_sponsored_type} ${newItem.defined_sponsored_by}`}
                                isSponsored
                                subjectTagDisableHover
                                authorTagLabel={newItem.post_author[0].name}
                                authorTagOnClick={() => {
                                    router.push(
                                        `autor/${newItem.post_author[0].slug}`
                                    )
                                }}
                                relatedTagLabel={newItem.post_category[0].name}
                                relatedTagOnClick={() => {
                                    if (
                                        newItem.post_category[0].name.toLowerCase() ===
                                        'coberturas especiais'
                                    ) {
                                        return window.open(
                                            `${checkSpecialOrInfoNews(
                                                newItem.post_category,
                                                newItem.post_slug,
                                                newItem.post_permalink
                                            )}
                                            `,
                                            '_self'
                                        )
                                    } else {
                                        return router.push(
                                            `/coberturas-especiais/${newItem.post_category[0].slug}`
                                        )
                                    }
                                }}
                            />
                        </div>
                    ))}
                </NewsListContainer>
            </NewsContainer>
        </SpecialCoverageContainer>
    )
}

export default SpecialCoverageComponent

const SpecialCoverageContainer = styled.div`
    box-sizing: border-box;
    padding-inline: 44px;
    padding-block: 44px;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        padding-inline: 36px;
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        padding-inline: 16px;
    }
`
const Title = styled.h4`
    ${fonts.jotaHeading04};
    color: ${colors.black.jotaBlack};
    margin: 0;
`

const NewsListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${spaces.jotaSpacing44};
    margin-top: 24px;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        grid-template-columns: repeat(1, 1fr);
        gap: ${spaces.jotaSpacing24};
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        grid-template-columns: repeat(1, 1fr);
        gap: ${spaces.jotaSpacing24};
    }
`

const NewsContainer = styled.div`
    display: flex;
    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        flex-direction: column;
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        flex-direction: column;
    }
`
